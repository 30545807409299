import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent implements OnInit {
  bootstrapstylecssUrl: string;
  appstylecssUrl: string;

  constructor(public sanitizer: DomSanitizer) { 
    this.sanitizer.sanitize;
    document.getElementById('lp-bootstrap-style').setAttribute('href', './assets/dashboard-assets/css/bootstrap.min.css')
    document.getElementById('lp-app-style').setAttribute('href', './assets/dashboard-assets/css/app.min.css')
 
    let node = document.createElement('script');
    node.innerText='feather.replace()';
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    node.src='./assets/dashboard-assets/libs/jquery/jquery.min.js'
    
    document.getElementsByTagName('body')[0].appendChild(node);

    let node1 = document.createElement('script');
    node1.innerText='feather.replace()';
    node1.type = 'text/javascript';
    node1.async = false;
    node1.charset = 'utf-8';
    node1.src='./assets/dashboard-assets/libs/bootstrap/js/bootstrap.bundle.min.js'
    
    document.getElementsByTagName('body')[0].appendChild(node1);

    
    let node2 = document.createElement('script');
    node2.innerText='feather.replace()';
    node2.type = 'text/javascript';
    node2.async = false;
    node2.charset = 'utf-8';
    node2.src='./assets/dashboard-assets/libs/metismenu/metisMenu.min.js'
    
    document.getElementsByTagName('body')[0].appendChild(node2);

    
    let node3 = document.createElement('script');
    node3.innerText='feather.replace()';
    node3.type = 'text/javascript';
    node3.async = false;
    node3.charset = 'utf-8';
    node3.src='./assets/dashboard-assets/js/app.js'
    
    document.getElementsByTagName('body')[0].appendChild(node3);
    const body = document.getElementsByTagName('body')[0];
    // body.classList.add('dashboard');
    // body.classList.add('en-us');
    // body.classList.add('partner-center');
    // body.classList.add('without-focus');
    // body.classList.add('allowAnimate');
    // body.classList.add('is-open');
    body.setAttribute('data-sidebar',"dark");
  }

  ngOnInit(): void {
    this.bootstrapstylecssUrl = './assets/dashboard-assets/css/bootstrap.min.css';
    this.appstylecssUrl = './assets/dashboard-assets/css/app.min.css';


    
  }

}
