import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/sign-in/services/authentication.service';

@Component({
  selector: 'app-dashboard-left-nav',
  templateUrl: './dashboard-left-nav.component.html',
  styleUrls: ['./dashboard-left-nav.component.css']
})
export class DashboardLeftNavComponent implements OnInit {

  roleID;
  isOpen;

  isSuperAdmin;
  isAdmin;
  isPAM;
  isPartner;
 
  constructor(private authService: AuthenticationService) {
    const usr = (this.authService.userValue && this.authService.userValue) ? this.authService.userValue : null;
    this.roleID =  usr.RoleID;
    if(this.roleID==1)
    {
      this.isAdmin=true;
      this.isPAM=false;
      this.isPartner= false;
      this.isSuperAdmin = false;
    }
    else if(this.roleID==3)
    {
      this.isPAM=true;
      this.isAdmin=false;
      this.isPartner= false;
      this.isSuperAdmin = false;
    }
    else if(this.roleID==2)
    {
      this.isAdmin=false;
      this.isPAM=false;
      this.isPartner= true;
      this.isSuperAdmin = false;
    }
    else if(this.roleID==4)
    {
      this.isAdmin=false;
      this.isPAM=false;
      this.isPartner= false;
      this.isSuperAdmin = true;
    }
   }
 
  
  ngOnInit(): void {
    this.isOpen = true;
   
  }

  onClick(event) {
    // const body = document.getElementsByTagName('body')[0];
    // const navID = document.getElementById('onedash-side-navigation');
    // if (this.isOpen) {
    //   navID.classList.remove('is-open');
    //   body.classList.remove('is-open');
    //   this.isOpen  = false;

    // } else {
    //   body.classList.add('is-open');
    //   navID.classList.add('is-open');
    //   this.isOpen  = true;
    // }


  }

  sspOnClick() {
    // const sspSubMenu= document.getElementById('ssp-sub-menu');
    // const sspMenu= document.getElementById('ssp-menu');
    // if(!this.isSSPMenuOpen){
    // this.isSSPMenuOpen= true;
    // sspMenu.classList.add('mm-active');
    // sspSubMenu.classList.add('mm-show');
    // }else{
    //   //if(!this.isSSPSubMenuOpen){
     
    //   this.isSSPMenuOpen = false;
    //   sspMenu.classList.remove('mm-active');
    //   sspSubMenu.classList.remove('mm-show');
    //  // }
    // }
  }

  sspSupportOnClick()
  {
 
    // const sspSubMenu= document.getElementById('ssp-sub-menu');
    // const sspSubSupportMenu= document.getElementById('ssp-sub-support-menu');
   
    // if(!this.isSSPSubMenuOpen){
    // this.isSSPSubMenuOpen= true;
  
    // sspSubMenu.classList.add('mm-active');
 
    // sspSubSupportMenu.classList.add('mm-show');
    
    // }else{
      
    //   this.isSSPSubMenuOpen = false;
    //   sspSubMenu.classList.remove('mm-active');
    //   sspSubSupportMenu.classList.remove('mm-show');

    // }

  }

  sspAnalyzeOnClick(){

    // const sspSubMenu= document.getElementById('ssp-sub-menu');
    // const sspSubAnalyzeMenu= document.getElementById('ssp-sub-analyse-menu');
   
    // if(!this.isSSPSubAnalyzeMenuOpen){
    // this.isSSPSubAnalyzeMenuOpen= true;
  
    // sspSubMenu.classList.add('mm-active');
 
    // sspSubAnalyzeMenu.classList.add('mm-show');
    
    // }else{
      
    //   this.isSSPSubAnalyzeMenuOpen = false;
    //   sspSubMenu.classList.remove('mm-active');
    //   sspSubAnalyzeMenu.classList.remove('mm-show');

    // }

  }


  spnOnClick() {
    // const spnSubMenu= document.getElementById('spn-sub-menu');
    // const spnMenu= document.getElementById('spn-menu');
    // if(!this.isSSPMenuOpen){
    // this.isSSPMenuOpen= true;
    // spnMenu.classList.add('mm-active');
    // spnSubMenu.classList.add('mm-show');
    // }else{
    //   //if(!this.isSSPSubMenuOpen){
     
    //   this.isSSPMenuOpen = false;
    //   spnMenu.classList.remove('mm-active');
    //   spnSubMenu.classList.remove('mm-show');
    //  // }
    // }
 
  } 
  spnEventsOnClick() {
    // const spnSubMenu= document.getElementById('spn-sub-menu');
    // const spnMenu= document.getElementById('spn-menu');
    // if(!this.isSSPMenuOpen){
    // this.isSSPMenuOpen= true;
    // spnMenu.classList.add('mm-active');
    // spnSubMenu.classList.add('mm-show');
    // }else{
    //   //if(!this.isSSPSubMenuOpen){
     
    //   this.isSSPMenuOpen = false;
    //   spnMenu.classList.remove('mm-active');
    //   spnSubMenu.classList.remove('mm-show');
    //  // }
    // }
  }
  spnEventsSubMenuOnClick() {
    // const spnSubMenu= document.getElementById('spn-sub-menu');
    // const spnEventsMenu= document.getElementById('spn-events-sub-menu');
    //  
    // if(!this.isSPNEventsSubMenuOpen){
    // this.isSPNEventsSubMenuOpen= true;
    // spnSubMenu.classList.add('mm-active');
    // spnEventsMenu.classList.add('mm-show');
    // }else{
      
    //   this.isSPNEventsSubMenuOpen = false;
    //   spnSubMenu.classList.remove('mm-active');
    //   spnEventsMenu.classList.remove('mm-show');
     
    // }
  }

  
  spnAssuranceSubMenuOnClick() {
    // const spnSubMenu= document.getElementById('spn-sub-menu');
    // const spnEventsMenu= document.getElementById('spn-assurance-sub-menu');
    //  
    // if(!this.isSPNAssuranceSubMenuOpen){
    // this.isSPNAssuranceSubMenuOpen= true;
    // spnSubMenu.classList.add('mm-active');
    // spnEventsMenu.classList.add('mm-show');
    // }else{
      
    //   this.isSPNAssuranceSubMenuOpen = false;
    //   spnSubMenu.classList.remove('mm-active');
    //   spnEventsMenu.classList.remove('mm-show');
     
    // }
  }
  spnBenefitsSubMenuOnClick() {
    // const spnSubMenu= document.getElementById('spn-sub-menu');
    // const spnBenefitsMenu= document.getElementById('spn-benefits-sub-menu');
    //  
    // if(!this.isSPNBenefitsMenuOpen){
    // this.isSPNBenefitsMenuOpen= true;
    // spnSubMenu.classList.add('mm-active');
    // spnBenefitsMenu.classList.add('mm-show');
    // }else{
      
    //   this.isSPNBenefitsMenuOpen = false;
    //   spnSubMenu.classList.remove('mm-active');
    //   spnBenefitsMenu.classList.remove('mm-show');
     
    // }
  }

  spnAnalyzeSubMenuOnClick() {
    // const spnSubMenu= document.getElementById('spn-sub-menu');
    // const spnAnalyzeMenu= document.getElementById('spn-analyze-sub-menu');
   
    // if(!this.isSPNAnalyzeMenuOpen){
    // this.isSPNAnalyzeMenuOpen= true;
    // spnSubMenu.classList.add('mm-active');
    // spnAnalyzeMenu.classList.add('mm-show');
    // }else{
      
    //   this.isSPNAnalyzeMenuOpen = false;
    //   spnSubMenu.classList.remove('mm-active');
    //   spnAnalyzeMenu.classList.remove('mm-show');
     
    // }
  }

  homeclicked()
  {
    this.removeActives()
    const spnSubMenu= document.getElementById('home-menu');
    spnSubMenu.classList.add('active');
  }

  sspOverviewclicked()
  {
    this.removeActives()
    // const sspmenu= document.getElementById('ssp-menu');
    // sspmenu.classList.add('mm-active');
    const spnSubMenu= document.getElementById('ssp-sub-overview-menu');
    spnSubMenu.classList.add('active');
  }
  sspTechnicalSupportclicked()
  {
    this.removeActives()
    // const sspmenu= document.getElementById('ssp-menu');
    // sspmenu.classList.add('mm-active');
    const spnSubMenu= document.getElementById('ssp-tech-support-menu');
    spnSubMenu.classList.add('active');
  }
  sspBusinessSupportclicked()
  {
    this.removeActives()
    // const sspmenu= document.getElementById('ssp-menu');
    // sspmenu.classList.add('mm-active');
    const spnSubMenu= document.getElementById('ssp-bus-support-menu');
    spnSubMenu.classList.add('active');
  }

  sspLeadsclicked()
  {
    this.removeActives()
    // const sspmenu= document.getElementById('ssp-menu');
    // sspmenu.classList.add('mm-active');
    const spnSubMenu= document.getElementById('ssp-leads-menu');
    spnSubMenu.classList.add('active');
  }
  sspSubsAnaclicked()
  {
    this.removeActives()
    // const sspmenu= document.getElementById('ssp-menu');
    // sspmenu.classList.add('mm-active');
    const spnSubMenu= document.getElementById('ssp-subs-ana-menu');
    spnSubMenu.classList.add('active');
  }

  removeActives()
  {
    const homem= document.getElementById('home-menu');
    homem.classList.remove('active');
    const spnSubOV= document.getElementById('ssp-sub-overview-menu');
    spnSubOV.classList.remove('active');
    const spnSubtechS= document.getElementById('ssp-tech-support-menu');
    spnSubtechS.classList.remove('active');
    const spnSubbusS= document.getElementById('ssp-bus-support-menu');
    spnSubbusS.classList.remove('active');
    const spnleads= document.getElementById('ssp-leads-menu');
    spnleads.classList.remove('active');
    const spnSubAna= document.getElementById('ssp-subs-ana-menu');
    spnSubAna.classList.remove('active');

  }
} 

