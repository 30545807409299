import { environment } from './../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MasterData, User } from './modals/user';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private userSubject: BehaviorSubject<User | any>;
  public user: Observable<User>;

  private isSignInSubject: BehaviorSubject<boolean>;

  public isSignIn: Observable<boolean>;

  private refreshTokenTimeout;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    const usr = localStorage.getItem('login');
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
    this.isSignInSubject = new BehaviorSubject<boolean>(usr ? true : false);
    this.isSignIn = this.isSignInSubject.asObservable();
  }
  public get userValue(): User {
    return this.userSubject.value;
  }

  public getIsSigned() {
    return this.isSignInSubject.value;
  }

  login(username, password) {

    return this.http.post<User>(`${environment.apiUrl}/User/CheckLoginDetails`,
      { username, password })
      .pipe(map(user => {
        //  tslint:disable-next-line: no-string-literal
        if (user['IsSuccessful']) {
          localStorage.removeItem('user');
          localStorage.removeItem('login');
          localStorage.setItem('user', JSON.stringify(user['objLoginDetails']));
          localStorage.setItem('login', "true");
          // tslint:disable-next-line: no-string-literal
          this.userSubject.next(user['objLoginDetails']);
          this.isSignInSubject.next(true);

          // this.startRefreshTokenTimer();
        }

        return user;

      }));
  }

  logout() {
    let refreshTokenDefault;
    if (this.userValue && this.userValue.AccessToken) {
      refreshTokenDefault = this.userValue.RefreshToken;
    }
    this.http.post<any>(`${environment.apiUrl}/User/RefreshTokens`, { 'RefreshToken': refreshTokenDefault }
    ).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.isSignInSubject.next(false);
    this.router.navigate(['/']);
    localStorage.removeItem('user');
    localStorage.removeItem('login');
  }

  refreshToken() {
    let refreshTokenDefault;
    if (this.userValue && this.userValue.AccessToken) {
      refreshTokenDefault = this.userValue.RefreshToken;
    }
    return this.http.post<any>(`${environment.apiUrl}/User/RefreshTokens`, { 'RefreshToken': refreshTokenDefault }
    )
      .pipe(map((user) => {
    
     
        let usr = JSON.parse(localStorage.getItem('user')) as User;
        if (usr) {
          usr.AccessToken = user['AccessToken'];
          usr.RefreshToken = user['RefreshToken'];
          localStorage.setItem('user', JSON.stringify(usr));
          this.userSubject.next(usr);
          this.isSignInSubject.next(true);
          this.startRefreshTokenTimer();
        }
        return user;
      })
      , catchError(this.errorHandler));


      
  }
  errorHandler(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
     // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      if(error.status == 401)
      {
      
        //this.router.navigate(['logIn']);
        localStorage.removeItem('user');
        localStorage.removeItem('login');
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  

  private startRefreshTokenTimer() {
    if (this.userValue && this.userValue.AccessToken) {
      const jwtToken = JSON.parse(atob(this.userValue.AccessToken.split('.')[1]));
      const expires = new Date(jwtToken.exp * 1000);

      console.log("expires: " + expires);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => { this.refreshToken().subscribe(); console.log("timeee------"); }, timeout);
    }

  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  } 

  /**
   *Get Partner Master data 
   * @param: MasterType   String parameter represents msatertype
*            MasterID     int masterid default send '0' to get all the materdate for the particulat mastertype
   * @return:             objMasterData with MasterID, amd MasterName, IsSuccessful bool,  SuccessMessage/ErrorMessage. 
   **/

  partnerService(MasterType, MasterID) {
    return this.http.post<MasterData>(`${environment.apiUrl}/MasterData/GetMasterDetails`,
      { MasterType, MasterID });

  }

  /**
   * Send email verification service Partner Signup
   * @param: ToEmailID    to whom the email verification to be sent
   * @return:             the code and status. 
   **/
  sendEmailVerificationCode(ToEmailID) {
    return this.http.post<any>(`${environment.apiUrl}/User/VerifyEmailID`,
      { ToEmailID });

  }

    /**
   * Create New Partner
   * @param: reqObj    obj with objPartnerDetails object and partnerTypeList values from form group
   * @return:             objLoginDetails, IsSuccessful bool,  SuccessMessage/ErrorMessage. 
   **/

  RegisterNewPartner(reqObj) {
    return this.http.post<any>(`${environment.apiUrl}/User/SaveNewPartner`,
      reqObj);

  }
   
  GetAmount(EmailNotificaionID:Number) {
    return this.http.post<any>(`${environment.apiUrl}/SalesTransaction/GetAmount`,
      { EmailNotificaionID });

  }

}
