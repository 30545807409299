import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../sign-in/services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const user = this.accountService.userValue;
        const currentUser = this.accountService.userValue && this.accountService.userValue ?  this.accountService.userValue: null;
        if (route.data.roles && route.data.roles.indexOf(currentUser.RoleName) === -1) {
            // role not authorised so redirect to home page
             
            this.router.navigate(['/']);
            return false;
        }
       else  if (currentUser) {
            
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
             
            this.router.navigate(['/']);
            return false;
        }
    }
}