import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/sign-in/services/authentication.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
  isSignIn;
  user;
  isColapse = false;
  IsOpenprofileDiv = false;
  constructor(public authService: AuthenticationService) { }

  ngOnInit(): void {

    this.isSignIn = this.authService.getIsSigned();
    this.user = (this.authService.userValue && this.authService.userValue) ? this.authService.userValue : null;

  }

  SignOut() {
    this.authService.logout();
  }

  ShowProfile() {
    this.IsOpenprofileDiv = false;
  }

  menuColapse() {
    /* if(this.isColapse ==false)
     {
       this.isColapse=true;
       const body = document.getElementsByTagName('body')[0];
       body.classList.add('sidebar-enable');
       body.classList.add('vertical-collpsed');
     }else
     {
       this.isColapse=false;
       const body = document.getElementsByTagName('body')[0];
       body.classList.remove('sidebar-enable');
       body.classList.remove('vertical-collpsed');
     }
        
 */

  }
}
