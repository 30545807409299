<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

    <div data-simplebar class="h-100">

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled" id="side-menu">
                <li *ngIf="isPartner">
                    <a class="waves-effect" id="home-menu" [routerLink]="['/dashboard']" (click)="homeclicked()">
                        <i class="fa fa-home fa-3x"></i>
                        <span>Home</span>
                    </a>
                </li>
                <li *ngIf="isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/partners']">
                        <i class="fa fa-users fa-3x"></i>
                        <span>Partners</span>
                    </a>
                </li>

                <li *ngIf="isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/pam-add-partner']">
                        <i class="fas fa-users fa-3x"></i>
                        <span>Add Partner</span>
                    </a>
                </li>
                <li *ngIf="isPAM">
                    <a class="waves-effect" [routerLink]="['/dashboard/pam-add-partner']">
                        <i class="fas fa-users fa-3x"></i>
                        <span>Add Partner</span>
                    </a>
                </li>
                <li *ngIf="isPAM || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/pam-partners']">
                        <i class="fas fa-users fa-3x"></i>
                        <span>Partners</span>
                    </a>
                </li>
                <li *ngIf="isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/update-incentives']">
                        <i class="far fa-money-bill-alt fa-3x"></i>
                        <span>Update Incentives</span>
                    </a>
                </li>

                <li *ngIf="isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/update-subscriptions']">
                        <i class="fa fa-bar-chart"></i>
                        <span>Subscription Analytics</span>
                    </a>
                </li>
                <li *ngIf="isPAM || isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/submit-lead-pam']">
                        <i class="fa fa-cubes"></i>
                        <span>Submit Deals</span>
                    </a>
                </li>

                <li *ngIf="isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/approve-deals']">
                        <i class="fa fa-cubes"></i>
                        <span>Update Deals</span>
                    </a>
                </li>

                <li *ngIf="isAdmin || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/close-deals']">
                        <i class="fa fa-cubes"></i>
                        <span>Close Deals</span>
                    </a>
                </li>

                <li *ngIf="isPAM || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/tech-support-req']">
                        <i class="fas fa-tools fa-3x"></i>
                        <span>Technical Support</span>
                    </a>
                </li>
                <li *ngIf="isPAM || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/bus-support-req']">
                        <i class="fas fa-toolbox fa-3x"></i>
                        <span>Business Support</span>
                    </a>
                </li>
                <li *ngIf="isPAM || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/inc-support-req']">
                        <i class="far fa-money-bill-alt fa-3x"></i>
                        <span>Incentives Support</span>
                    </a>
                </li>
                <li *ngIf="isPAM || isSuperAdmin">
                    <a class="waves-effect" [routerLink]="['/dashboard/gen-support-req']">
                        <i class="fas fa-hands-helping fa-3x"></i>
                        <span>General Support</span>
                    </a>
                </li>
                <li *ngIf="isPAM || isSuperAdmin"><a class="has-arrow"><i class="fa fa-users"></i>Signulu Assurance</a>
                    <ul class="menu-pad   mm-collapse" id="spn-assurance-sub-menu-pam" aria-expanded="true">
                        <li><a [routerLink]="['/dashboard/training-request']">Training</a></li>
                        <li><a [routerLink]="['/dashboard/planning-request']">Planing</a></li>
                        <li><a [routerLink]="['/dashboard/expertssp-request']">Expert SSP</a></li>
                        <li><a [routerLink]="['/dashboard/enroll-request']">Assurance Enrollment</a></li>
                    </ul>
                </li>

                <li *ngIf="isPartner" id="ssp-menu">
                    <a class="has-arrow waves-effect" (click)="sspOnClick()">
                        <i class="fas fa-atom fa-3x "></i>
                        <span>SSP</span>
                    </a>
                    <ul class="sub-menu mm-collapse" id="ssp-sub-menu" aria-expanded="true">
                        <li>
                            <a [routerLink]="['/dashboard/OverView']" id="ssp-sub-overview-menu" (click)="sspOverviewclicked()"> <i class="mdi mdi-view-dashboard"></i><span>Overview</span></a>
                        </li>
                        <li>
                            <a class="has-arrow"> <i class="fas fa-hands-helping"></i>Support</a>
                            <ul class="menu-pad   mm-collapse" id="ssp-sub-support-menu" aria-expanded="true">
                                <li><a [routerLink]="['/dashboard/tech-support']" id="ssp-tech-support-menu" (click)="sspTechnicalSupportclicked()">Technical Support</a></li>
                                <li><a [routerLink]="['/dashboard/business-support']" id="ssp-bus-support-menu" (click)="sspBusinessSupportclicked()">Business Support</a></li>
                            </ul>
                        </li>
                        <li><a class="has-arrow"><i class="fa fa-bar-chart"></i>Analyze</a>
                            <ul class="menu-pad  mm-collapse" id="ssp-sub-analyse-menu" aria-expanded="true">
                                <li><a [routerLink]="['/dashboard/lead']" id="ssp-leads-menu" (click)="sspLeadsclicked()">Lead</a></li>
                                <li><a [routerLink]="['/dashboard/analytics']" id="ssp-subs-ana-menu" (click)="sspSubsAnaclicked()">Subscription analytics</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li *ngIf="isPartner" id="spn-menu">
                    <a class="has-arrow waves-effect" (click)="spnOnClick()">
                        <i class="fa fa-fast-forward"></i>
                        <span>SPN</span>
                    </a>

                    <ul class="sub-menu mm-collapse" id="spn-sub-menu" aria-expanded="true">
                        <li><a [routerLink]="['/dashboard/spn-overview']"><i class="mdi mdi-view-dashboard"></i>Overview</a></li>
                        <li><a [routerLink]="['/dashboard/spn-compitencies']"><i class="fa fa-spinner" ></i>Competencies</a></li>
                        <li><a [routerLink]="['/events']"><i class="fa fa-camera-retro"></i>Events</a>

                        </li>
                        <li><a class="has-arrow"><i class="fa fa-users"></i>Signulu Assurance</a>
                            <ul class="menu-pad   mm-collapse" id="spn-assurance-sub-menu" aria-expanded="true">
                                <li><a [routerLink]="['/dashboard/spn-training']">Training</a></li>
                                <li><a [routerLink]="['/dashboard/spn-planing']">Planing</a></li>
                                <li><a [routerLink]="['/dashboard/spn-export-ssp']">Expert SSP</a></li>
                                <li><a [routerLink]="['/dashboard/spn-assurance-enrollment']">Assurance Enrollment</a></li>
                            </ul>
                        </li>
                        <li><a class="has-arrow"><i class="mdi mdi-star-four-points-outline"></i>Benefits</a>
                            <ul class="menu-pad   mm-collapse" id="spn-benefits-sub-menu" aria-expanded="true">
                                <!-- <li><a [routerLink]="['/dashboard/spn-tech-benfits']">Technical Benefits</a></li> -->
                                <!-- <li><a [routerLink]="['/dashboard/spn-goto-market']">Go-to-market</a></li> -->
                                <!-- <li><a [routerLink]="['/dashboard/spn-marketplace-rewards']">Marketplace Rewards</a></li> -->
                                <li><a [routerLink]="['/dashboard/spn-logo-builder']">Logo Builder</a></li>
                            </ul>
                        </li>
                        <!-- <li><a class="has-arrow"><i class="fa fa-bar-chart"></i>Analyze</a>
                            <ul class="menu-pad   mm-collapse" id="spn-analyze-sub-menu" aria-expanded="true">
                                <li><a>Lead</a></li>
                                <li><a>Subscription analytics</a></li>
                            </ul>
                        </li> -->
                    </ul>
                </li>


                <li *ngIf="isPartner" id="my-bus-leads-menu">
                    <a class="has-arrow waves-effect">
                        <i class="fa fa-cubes"></i>
                        <span>My Business Leads</span>
                    </a>
                    <ul class="sub-menu mm-collapse" id="my-bus-leads-sub-menu" aria-expanded="true">
                        <li><a [routerLink]="['/dashboard/bl-business-profile']"><i class="fa fa-address-book-o"></i>Business Profile</a></li>
                        <li><a [routerLink]="['/dashboard/bl-co-sell-opportunities']"><i class="fa fa-street-view"></i>Co-sell opportunities</a></li>
                        <li><a class="has-arrow waves-effect"><i class="fa fa-exchange"></i>Leads</a>
                            <ul class="ssub-menu">
                                <li><a class=" has-arrow">Deal registrations</a>
                                    <ul class="ssub-menu mm-collapse" aria-expanded="true">
                                        <li><a [routerLink]="['/dashboard/bl-ld-dr-submit-newlead']">Submit New Lead</a></li>
                                        <li><a [routerLink]="['/dashboard/bl-ld-dr-inprogress-leads']">Inprogress Leads</a></li>
                                        <li><a [routerLink]="['/dashboard/bl-ld-dr-closed-deals']">Closed Deals</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li *ngIf="isPartner" id="incentives-menu">
                    <a class="has-arrow waves-effect">
                        <i class="fa fa-money" aria-hidden="true"></i>
                        <span>Incentives</span>
                    </a>
                    <ul class="sub-menu mm-collapse" id="incentives-sub-menu" aria-expanded="true">
                        <li><a [routerLink]="['/dashboard/ins-overview']"><i class="mdi mdi-view-dashboard"></i>Overview</a></li>
                        <!-- <li><a [routerLink]="['/dashboard/ins-programs']"><i class="fa fa-eye"></i>Programs</a>
                        </li> -->
                        <!-- <li><a><i class="fa fa-eye"></i>Plans Managements</a>
                        </li> -->
                        <li><a [routerLink]="['/dashboard/ins-support']"><i class="fas fa-hands-helping"></i>Support</a>
                        </li>
                    </ul>
                </li>

                <li *ngIf="isPartner">
                    <a [routerLink]="['/dashboard/gen-support']" class="waves-effect">
                        <i class="fas fa-hands-helping"></i>
                        <span>Support</span>
                    </a>
                </li>
                <li *ngIf="isAdmin || isSuperAdmin">
                    <a [routerLink]="['/dashboard/sales-card']" class="waves-effect">
                        <i class="fas fa-credit-card"></i>
                        <span>Signulu PowerPack</span>
                    </a>
                </li>
                <li *ngIf="isPartner">
                    <a [routerLink]="['/dashboard/activation-form']" class="waves-effect">
                        <i class="fas fa-credit-card"></i>
                        <span>Activation Form</span>
                    </a>
                </li>



            </ul>
        </div>
        <!-- Sidebar -->
    </div>
</div>
<!-- Left Sidebar End -->